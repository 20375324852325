import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';


export interface TextToSpeechStepProps {
  synthesizedAudio: Blob
}

export const TextToSpeechStep: React.FunctionComponent<TextToSpeechStepProps> = ({
  synthesizedAudio
}) => {
  function show_audio() {
    if (synthesizedAudio.size > 0) {
        const url = URL.createObjectURL(synthesizedAudio);
        return (           
          <>
          <h3 className='mb-4x'>Translated audio:</h3>
            <ReactAudioPlayer
                src={url}
                controls
            />
            </>
        );
    }
  }


    return (
    <>
   
   
    {show_audio()}
    </>
  );
}

