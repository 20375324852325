import { Button, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap'
import React from 'react'
import {  ClipboardIcon, LanguageSelector, TextArea , SpeakerIcon } from './'
import { TextToSpeechStep } from './TextToSpeechStep'
import { SectionType} from '../types.d'

export interface TranslationOutputProps  {
    toLanguage: "en" | "de" | "hu",
    setToLanguage: (toLanguage: "en" | "de" | "hu") => void,
    loading: boolean,
    translatedText: string,
    setTranslatedText: (translatedText: string) => void,
    targetClipboard: React.MutableRefObject<null>,
    handleClipboardClick:  () => void,
    text: string,
    showClipboardTooltip: boolean,
    handleShowClipboardTooltip: () => void,
    synthesizedAudio: Blob,
    handleSpeakerClick: () => void


}

export const TranslationOutput: React.FunctionComponent<
 TranslationOutputProps 
> = ({
    toLanguage,
    setToLanguage,
    loading,
    translatedText,
    setTranslatedText,
    targetClipboard,
    handleClipboardClick,
    text,
    showClipboardTooltip,
    handleShowClipboardTooltip,
    synthesizedAudio,
    handleSpeakerClick

}) => {
  return (
    <>
      <LanguageSelector
        type={SectionType.To}
        value={toLanguage}
        onChange={setToLanguage}
      />

      <div className="textarea-icon-buttons">
        <TextArea
          type={SectionType.To}
          loading={loading}
          text={translatedText}
          onChange={setTranslatedText}
        />

        <div className="textarea-icon-buttons-to">
          <>
            <OverlayTrigger
              key={"right"}
              placement={"right"}
              overlay={
                <Tooltip id={"tooltip-right"}>Copy to clipboard</Tooltip>
              }
              rootClose={true}
              transition={false}
            >
              <Button
                ref={targetClipboard}
                variant="link"
                disabled={loading}
                onClick={handleClipboardClick}
                hidden={text === "" || loading}
              >
                <ClipboardIcon />
              </Button>
            </OverlayTrigger>
            <Overlay
              target={targetClipboard.current}
              show={showClipboardTooltip}
              placement="bottom"
              rootClose={true}
              onHide={handleShowClipboardTooltip}
              transition={false}
            >
              {(props) => (
                <Tooltip id="overlay-cliboard" {...props}>
                  Copied to clipboard
                </Tooltip>
              )}
            </Overlay>
          </>
          <Button
                    variant='link'
                    hidden={ translatedText === '' }
                    onClick={handleSpeakerClick}
                  >
                    <SpeakerIcon />
                  </Button>
        </div>
      </div>
      <div className="mt-5"></div>
      <TextToSpeechStep synthesizedAudio={synthesizedAudio} />
    </>
  );
};
