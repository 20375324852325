import * as React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { TranslatedArea } from './TranslatedArea';

export interface SpeechToTextStepProps {
  audioRecording: Blob,
  detectedText: string
}

export const SpeechToTextStep: React.FunctionComponent<SpeechToTextStepProps> = ({
  audioRecording,
  detectedText,
}) => {

    function show_audio() {
      if (audioRecording.size > 0) {

          const url = URL.createObjectURL(audioRecording);

          
          return (
             <>
             <h3 className='mb-4x'>Recorded audio:</h3>
              <ReactAudioPlayer
                  src={url}
                  controls
              />
              </>
          );
      }
  }


    return (
    <>
    {show_audio()}
    </>
  );
}

