export const ArrowsIcon = () => (
  <svg focusable='false' xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
    <path d='M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z'></path>
  </svg>
)

export const ClearIcon = () => (
  <svg focusable='false' xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'></path>
  </svg>
)

export const ClipboardIcon = () => (
  <svg focusable='false' xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24' viewBox='0 0 24 24' width='24'>
    <g>
      <rect fill='none' height='24' width='24'></rect>
      </g><g>
      <path d='M16,20H5V6H3v14c0,1.1,0.9,2,2,2h11V20z M20,16V4c0-1.1-0.9-2-2-2H9C7.9,2,7,2.9,7,4v12c0,1.1,0.9,2,2,2h9 C19.1,18,20,17.1,20,16z M18,16H9V4h9V16z'></path>
    </g>
  </svg>
)

export const SpeakerIcon = () => (
  <svg focusable='false' xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
    <path d='M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z'></path>
  </svg>
)
