import * as React from 'react';

import { AudioRecorder } from 'react-audio-voice-recorder';



const audioOptions = {
    sampleRate: 16000,
    bitsPerSample: 16,
    channelCount: 1
};

export interface VoiceRecorderProps {
    audioRecording?: Blob;
    setAudioRecording: (newRecording: Blob) => void;
}

export const VoiceRecorder: React.FunctionComponent<VoiceRecorderProps> = ({
    audioRecording,
    setAudioRecording,
  
}) => {
    
    

    function record_audio() {
        return (
            <div id="audioRecorder">
            <AudioRecorder
                onRecordingComplete={(blob) => setAudioRecording(blob)}
                
                audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                    sampleRate: audioOptions.sampleRate,
                    sampleSize: audioOptions.bitsPerSample,
                    channelCount: audioOptions.channelCount,
                    autoGainControl: true,
                    // deviceId,
                    // groupId,
                }}
                onNotAllowedOrFound={(err) => console.table(err)}
                downloadOnSavePress={false}
                downloadFileExtension="webm"
                mediaRecorderOptions={{
                    audioBitsPerSecond: 128000,
                }}
                // showVisualizer={true}
                />
            </div>
        )
    }

   
    return (
    < div className="p-2x">
    
        {record_audio()}

    </div>
  );
}

