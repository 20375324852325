import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";

export const Loading = () => {
  return <Button
  color="primary"
  disabled
>
  <Spinner size="sm">
   
  </Spinner>
  <span>
    {' '}Loading
  </span>
</Button>

};
