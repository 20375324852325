import * as React from 'react'
import './PageHeader.scss'

const title = 'BMW Translator – POC Debrecen'
export const PageHeader: React.FunctionComponent = () => {
  return (
  <div className='ds-header mb-4x'>
    <header className="ds-header__wrapper ds-header__wrapper--large">
    <span className="ds-header__wrapper__application col">
      <span className="ds-header__logos-wrapper">
      <span className="ds-header__logos-wrapper__content ds-header__logos-wrapper__content--default"></span>
       <span className="ds-header__vertical-divider"></span>
        </span>
         <span className="ds-header__app-name-wrapper title-md"> {title} </span>
      </span>
    </header>
  </div>
  );
};