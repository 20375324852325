import axios from "axios";
import applyCaseMiddleware from 'axios-case-converter';

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

/**
 * Models are converted automatically to snake case before sending to the python backend
 */

export interface TranslationRequest {
    text: string, 
    sourceLanguage: string, 
    targetLanguage: string
}

export interface SpeechSynthRequest {
    text: string, 
    targetLanguage: string
}

export class TranslationService {
    private static _classInstance: TranslationService | null = null;
    public static get classInstance(): TranslationService {
        if (TranslationService._classInstance === null) {
            TranslationService._classInstance = new TranslationService();
        }
        return TranslationService._classInstance;
    }

    readonly api = applyCaseMiddleware(axios.create({ baseURL: baseUrl }), { ignoreHeaders: true });



    public async translateText(translationRequest: TranslationRequest): Promise<string> {
       
        const response = await this.api.post<string>("/translate", translationRequest);
        return response.data;
    }

    public async synthesiseSpeech(speechSynthRequest: SpeechSynthRequest): Promise<Blob> {
        const response = await this.api.post<Blob>("/text-to-speech", speechSynthRequest, {
            responseType: "blob"
        });
        console.log("response:", response);
        return response.data;
    }

}


