import { TranslationService } from '../services/TranslationService';

const translationService = TranslationService.classInstance;

export async function startTextToSpeech(translationResult: string, targetLanguage: string, setSynthesizedAudio: (blob: Blob) => void) {
    try {
      const synthResult = await translationService.synthesiseSpeech({
        text: translationResult,
        targetLanguage
      });
  
      setSynthesizedAudio(synthResult);
    } catch (ex) {
      console.error("Error during TTS:", ex);
    }
  }

export  async function startTranslation( text: string, sourceLanguage: string, targetLanguage: string , textOnly : boolean, setSynthesizedAudio: (blob: Blob) => void) {
    try {
      if (sourceLanguage === targetLanguage)
      return text
      const translationResult = await translationService.translateText(
        {text,
        sourceLanguage,
        targetLanguage}
      );
  
      if(textOnly === false){
        startTextToSpeech(translationResult, targetLanguage, setSynthesizedAudio);
      }

      return translationResult
  
    } catch (ex) {
      console.error("Error during translation:", ex);
    }
  }