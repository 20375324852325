import { Button, Col, Container,Row} from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import React from 'react'
import { ArrowsIcon} from './components'
import { useDebounce, useStore } from './hooks'
import { PageHeader } from "./components/PageHeader/PageHeader"
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { TranslationInput } from "./components/TranslationInput"
import { TranslationOutput } from './components/TranslationOutput'
import {  startTranslation, startTextToSpeech } from "./utils/util"

const socketUrl = process.env.REACT_APP_STT_WEBSOCKET_URL ?? "ws://localhost:8000/websocket/speech-to-text";

const transcriptionFinishedMarker = "$TRANSCRIPTION_FINISHED$";

 
export function App () {

  const [showClipboardTooltip, setShowClipboardTooltip] = useState(false)
  const targetClipboard = useRef(null)
  const [audioRecording, setAudioRecording] = useState(new Blob());
  const [sourceLanguage, setSourceLanguage] = useState("en");
  const [targetLanguage, setTargetLanguage] = useState("de");
  const [detectedText, setDetectedText] = useState("");
  const [synthesizedAudio, setSynthesizedAudio] = useState(new Blob());
  const [load, setLoad] = useState(false)

  const {
    fromLanguage,
    toLanguage,
    text,
    translatedText,
    loading,
    interchangeLanguages,
    setFromLanguage,
    setToLanguage,
    setText,
    setTranslatedText
  } = useStore()

  const debouncedText = useDebounce(text, 320)

  async function   handleSpeakerClick()
    {
      console.log("*** listen Record ***");
      startTextToSpeech(translatedText, targetLanguage, setSynthesizedAudio);
    }

  useEffect(() => {
    console.log('audio :', audioRecording);
    handleTranslationStart()
  }, [audioRecording])
  
  useEffect(() => {
    if (debouncedText === '') return

    const text = debouncedText;
    const  sourceLanguage = fromLanguage ;
    const targetLanguage = toLanguage; 
    const textOnly = true;


    async function translateAndSetResult() {
      try {
        const translatedTextResult = await startTranslation(text, sourceLanguage, targetLanguage, textOnly,  setSynthesizedAudio);
      
        if (translatedTextResult === null || translatedTextResult === undefined) return
      
        setTranslatedText(translatedTextResult)             
      }
      catch(error){
      
        setTranslatedText('Something went wrong')
      }
    }
    translateAndSetResult() 
    
  }, [fromLanguage, toLanguage, debouncedText])
 
  const handleClearClick = () => {
    setText('')
    setTranslatedText('')
    setAudioRecording(new Blob())
    setSynthesizedAudio(new Blob())
  }

  const handleShowClipboardTooltip = () => {
    setShowClipboardTooltip(false)
  }

  const handleClipboardClick = () => {
    setShowClipboardTooltip(!showClipboardTooltip)
    void navigator.clipboard.writeText(translatedText)
  }

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 20,
      reconnectInterval: 4000,
      onMessage: (event: MessageEvent<string>) => {
        console.log("Received event:", event);
        const transcribed_text = event.data;

        
        if (transcribed_text === transcriptionFinishedMarker) {
          // state update might not be final yet, so we add a small timeout
          setTimeout(() => startTranslation(detectedText, fromLanguage, toLanguage, false,  setSynthesizedAudio), 200);
          setText(detectedText)
          return;
        }

        // use update function to avoid async issues, because state updates are batched in react
        // it's possible that the state variable does not have the latest update yet
        setDetectedText((currentText) => {
          if (currentText === "") {
            return transcribed_text;
          }
          return currentText + " " + transcribed_text;
        });

       
        setLoad(false)
      }
  });

  function handleTranslationStart() {
    
    console.log("starting translation");
    startSpeechToText();
  }

  function startSpeechToText() {
    if (audioRecording.size < 0) {
      
      return;
    }
    resetOutputs();
  
    sendMessage(fromLanguage);
    sendMessage(audioRecording);
  }
  
  function resetOutputs() {
    setDetectedText("");
    setTranslatedText("");
    setSynthesizedAudio(new Blob());
  }

  const addAudioElement = (blob : Blob) => {
    setLoad(true)
    setAudioRecording(blob);         
  };

  return (
    <>
     <PageHeader/>
    <Container fluid>
    <div>
        <span className='translate-text'></span>
      </div>
      <Row>
        <Col xs={12} md={5}>
          <TranslationInput  fromLanguage={fromLanguage}
          setFromLanguage={setFromLanguage}
          text={text}
          setText={setText}
          handleClearClick={handleClearClick}
          audioRecording={audioRecording}
          addAudioElement={addAudioElement}
          load={load}
          detectedText={detectedText}
          />           
        </Col>
        <Col xs={12} md={2} className='px-0'>
        <Button
            variant='link'
            onClick={interchangeLanguages}
            disabled={fromLanguage ===  toLanguage}
          >
            <ArrowsIcon />
          </Button>
        </Col>
        <Col xs={12} md={5}>
         < TranslationOutput 
                toLanguage={toLanguage}
                setToLanguage={setToLanguage}
                loading={loading}
                translatedText={translatedText}
                setTranslatedText={setTranslatedText}
                targetClipboard={targetClipboard}
                handleClipboardClick={handleClipboardClick}
                text={text}
                showClipboardTooltip={showClipboardTooltip}
                handleShowClipboardTooltip={handleShowClipboardTooltip}
                synthesizedAudio={synthesizedAudio}
                handleSpeakerClick={handleSpeakerClick}
         />
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default App
