import {
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import * as React from "react";
import {
  ClearIcon,
  LanguageSelector,
  TextArea,
} from "./";
import { VoiceRecorder } from "./VoiceRecorder";
import { SpeechToTextStep } from "./SpeechToTextStep";
import { SectionType, FromLanguage } from "../types.d";
import { Loading } from "./Loading";

export interface TranslationInputProps {
  fromLanguage: "en" | "de" | "hu";
  setFromLanguage: (fromLanguage: FromLanguage) => void;
  text: string;
  setText: (text: string) => void;
  handleClearClick: () => void;
  audioRecording: Blob;
  addAudioElement: (blob: Blob) => void;
  load: boolean;
  detectedText: string;
}

export const TranslationInput: React.FunctionComponent<
  TranslationInputProps
> = ({
  fromLanguage,
  setFromLanguage,
  text,
  setText,
  handleClearClick,
  audioRecording,
  addAudioElement,
  load,
  detectedText,
}) => {
  return (
    <>
      <LanguageSelector
        type={SectionType.From}
        value={fromLanguage}
        onChange={setFromLanguage}
      />

      <div className="textarea-icon-buttons">
        <TextArea type={SectionType.From} text={text} onChange={setText} />

        <div className="textarea-clear-button">
          <Button
            variant="link"
            onClick={handleClearClick}
            disabled={text === ""}
            hidden={text === ""}
          >
            <ClearIcon />
          </Button>
        </div>

        <div className="textarea-icon-buttons-from">
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={<Tooltip id={"tooltip-right"}>Listen to the text</Tooltip>}
            rootClose={true}
            transition={false}
          >
            <VoiceRecorder
              audioRecording={audioRecording}
              setAudioRecording={addAudioElement}
            />
          </OverlayTrigger>
        </div>
      </div>
      {load && <Loading />}
      <SpeechToTextStep
        audioRecording={audioRecording}
        detectedText={detectedText}
      />
    </>
  );
};
